<template>
	<div id="wayPassengerFlow">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="siteName" label="站点:">
				<div @click="selectStation">
					<el-input placeholder="请选择" v-model="form.siteName"></el-input>
				</div>
			</jy-query-item>
			<jy-query-item prop="dateType" label="日期类型:">
				<el-select v-model="form.dateType" placeholder="请选择">
					<el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="查询日期:" prop="searchByYearMonth">
				<el-date-picker v-model="form.searchByYearMonth" value-format="yyyy-MM" type="month" placeholder="选择日期">
				</el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
				<!-- <el-row> -->
				<!-- <el-button type="primary">导出</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<el-row>
			<el-col :span="6" class="select_box">
				<el-radio v-model="radio" label="1">上行</el-radio>
				<el-radio v-model="radio" label="2">下行</el-radio>
			</el-col>
		</el-row>
		<echart :list="elist"></echart>
		<jy-table max-height="595" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="routeName" label="线路"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
			<jy-table-column prop="dir" label="方向"></jy-table-column>
			<jy-table-column prop="stopNum" label="经停班次数"></jy-table-column>
			<jy-table-column prop="getOnTotal" label="上车人数汇总"></jy-table-column>
			<jy-table-column prop="getOffTotal" label="下车人数汇总"></jy-table-column>
		</jy-table>
		<div class="onCarBox">
			上车人数总计：{{ ontotal }}
			<div class="secondBox">下车人数总计：{{ offtotal }}</div>
		</div>
		<!-- <div class="jy_pagination">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                                </el-pagination>
                            </div> -->
		<add-dialog ref="dialog" @updateList="getList" @pushStation="pushStation"></add-dialog>
	</div>
</template>
<script>
import echart from "@/components/pages/admin/statisticalAnalysis/passengerFlow/wayPassengerFlow/charts";
import adddialog from "@/components/pages/admin/statisticalAnalysis/passengerFlow/wayPassengerFlow/checkDetails";
export default {
	data() {
		return {
			// echarts列表
			elist: [],
			radio: "1",

			ontotal: "0",
			offtotal: "0",
			upGetOnSumNum: "0",
			upGetOffSumNum: "0",
			downGetOnSumNum: "0",
			downGetOffSumNum: "0",

			form: {
				siteName: "",
				searchByYearMonth: "",
				dateType: ""
			},
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			dataList: [
				{
					routeName: "",
					dir: "",
					stopNum: "",
					getOnTotal: "",
					getOffTotal: ""
				}
			],
			upList: [],
			downList: [],
			typeoptions: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "1",
					label: "工作日"
				},
				{
					value: "2",
					label: "周末"
				}
			]
		};
	},
	created() {
		this.getList();
		this.getDate();
	},
	components: {
		echart,
		"add-dialog": adddialog
	},
	watch: {
		radio: {
			handler(val) {
				if (val == "1") {
					this.elist = this.upList;
					this.dataList = this.upList;
					this.ontotal = this.upGetOnSumNum;
					this.offtotal = this.upGetOffSumNum;
				} else {
					this.elist = this.downList;
					this.dataList = this.downList;
					this.ontotal = this.downGetOnSumNum;
					this.offtotal = this.downGetOffSumNum;
				}
			},
			immediate: true
		}
	},
	methods: {
		getDate() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth();
			if (month == 0) {
				month = 12;
			}
			if (month < 10) {
				month = "0" + month;
			}
			var nowDate = year + "-" + month;
			this.form.searchByYearMonth = nowDate;
		},
		getList() {
			let option = {
				...this.form
				// pageIndex: this.pageIndex,
				// pageSize: this.pageSize
			};
			let url = "/siteRoutePFAnalyze/queryPageList";
			this.$http.post(url, option).then(res => {
				if (res.retain == "1") {
					this.dataList = [];
					this.$message({
						message: res.error,
						type: "warning"
					});
				} else {
					this.upList = res.detail.upList;
					this.downList = res.detail.downList;
					this.upGetOnSumNum = res.detail.upGetOnSumNum;
					this.upGetOffSumNum = res.detail.upGetOffSumNum;
					this.downGetOnSumNum = res.detail.downGetOnSumNum;
					this.downGetOffSumNum = res.detail.downGetOffSumNum;
					this.radio = "1";
					this.elist = this.upList;
					this.dataList = this.upList;
					this.ontotal = this.upGetOnSumNum;
					this.offtotal = this.upGetOffSumNum;
					// this.total = res.detail.total
				}
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 选择站点
		selectStation() {
			this.$refs.dialog.init();
		},
		// 输入站点
		pushStation(row) {
			this.form.siteName = row.name;
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped="wayPassengerFlow">
#wayPassengerFlow {
	.select_box {
		margin-left: 50px;
	}

	.onCarBox {
		padding-top: 10px;
		font-size: 12px;

		.secondBox {
			display: inline-block;
			margin-left: 20px;
		}
	}
}
</style>
